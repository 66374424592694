import { LocaleType, useIntl } from '@checkout-ui/shared-context-locale';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import dynamic from 'next/dynamic';
import Head from 'next/head';

export const getServerSideProps: GetServerSideProps = async (context) => {
  const defaultLocale = context.defaultLocale;
  const clientLocale = context.locale as LocaleType;

  const locales = context.locales;

  const locale = clientLocale || defaultLocale;

  return {
    props: {
      locale,
      locales,
    },
  };
};

const Demoshop = dynamic(() => import('../../components/home'), {
  ssr: false,
});
const Index = ({
  locale,
  locales,
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { formatMessage } = useIntl();
  const description = formatMessage({
    id: 'demo-shop.configuration-panel.heading',
  });
  const title = formatMessage({
    id: 'demo-shop.title',
  });
  return (
    <>
      <Head>
        <meta name="robots" content="index, follow" />
        <title>{title}</title>
        <meta name="description" content={description} key="description" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={description} />
        <meta property="og:locale" content={locale} />
        <meta property="og:url" content="https://demo.billie.io/" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content={title} />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:image:alt" content={title} />
        <meta name="twitter:description" content={description} />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="627" />
        <meta name="author" content="Billie" />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@Billie_Payments" />
        <meta name="twitter:image" content="/img/demo-shop.png" />
        <meta name="description" content={description} />
        <meta
          name="viewport"
          content="width=device-width, minimum-scale=1, initial-scale=1, shrink-to-fit=no"
        />
        <meta httpEquiv="x-ua-compatible" content="ie=edge" />
        <link
          rel="shortcut icon"
          href="/favicon.ico"
          media="(prefers-color-scheme: light"
        />
        <link
          rel="shortcut icon"
          href="/favicon-light.ico"
          media="(prefers-color-scheme: dark)"
        />
        <meta property="og:image" content="/img/demo-shop.png" />
        <link rel="canonical" href="/demo.billie.io" />
        <link rel="manifest" href="/manifest.json" />
        {locales.length &&
          locales.map((locale) => (
            <meta
              key={locale}
              property="og:locale:alternate"
              content={locale}
            />
          ))}
      </Head>
      <Demoshop />
    </>
  );
};
export default Index;
